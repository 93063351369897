// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Platform from './pages/Platform';
import Pricing from './pages/Pricing';
import "./App.css"
import Footer from './components/Footer';
import StudentDetail from './pages/StudentDetail';
import TeacherDetail from './pages/TeacherDetail';
import InstitutionDetail from './pages/InstitutionDetail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

function App() {
  const PlatformRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Platform />} />
            <Route path="studentDetail" element={<StudentDetail />} />
            <Route path="teacherDetail" element={<TeacherDetail />} />
            <Route path="institutionDetail" element={<InstitutionDetail />} />
        </Routes>
    );
};
  return (
    <Router>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/platform/*" element={<PlatformRoutes />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/gizlilik-ve-cerez-politikasi" element={<PrivacyPolicy />} />
        <Route path="/uyelik-sozlesmesi-ve-site-kullanim-kosullari" element={<TermsOfService />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
