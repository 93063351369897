import React from 'react';
import privacyDocument from "../documents/Talent14-Gizlilik-ve-Cerez-Politikasi.pdf";

function PrivacyPolicy() {
  return (
    <>
      <iframe
        src={privacyDocument}
        width="100%"
        height="800px"
        title="Privacy and Cookie Policy"
        style={{ border: "none" }}
      />
    </>
  );
}

export default PrivacyPolicy;
