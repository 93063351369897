import React from 'react';
import termsDocument from "../documents/Talent14-Uyelik-Sozlesmesi-Ve-Site-Kullanim-Kosullari.pdf";

function TermsOfService() {
  return (
    <>
      <iframe
        src={termsDocument}
        width="100%"
        height="800px"
        title="Membership Agreement and Terms of Use"
        style={{ border: "none" }}
      />
    </>
  );
}

export default TermsOfService;
